/**
 * Override any slipway theme items here
 */

import Logo from './logo.inline.svg'
import LogoMobile from './logo-mobile.inline.svg'
// @ts-ignore
import HeroBg from './hero-background.jpeg'
// @ts-ignore
import TestimonialsAvatar from './avatar.jpg'

export default {
  Logo,
  LogoMobile,
  HeroBg,
  TestimonialsAvatar,
  heroBackgroundPosition: 'top right 22%'
}
